import * as types from './types';
import _ from 'lodash';

const initialState = {
  address: {
    zipcode: '',
    street: '',
    state: '',
    city: '',
  },
  stripeCustomerId: '',
  apponentTrack: null,
  emailVerified: null,
  countryId: '',
  userRoleId: '',
  modified: '',
  userRole: [],
  created: '',
  gender: '',
  status: '',
  fname: '',
  email: '',
  image: '',
  lname: '',
  bio: '',
  dob: '',
  hcp: '',
  id: '',
  topEight: '',
  prizeMoney: '',
  winnerCount: '',
  token: null,
  languageCode: '',
  countries: [],
  myCountry: {},
  countryId: '',
  terms: '',
  userTerms: '',
  faq: '',
  faqDetail: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.REGISTER_USER: {
      if (
        action.payload &&
        action.payload.data &&
        action.payload.status === 200
      ) {
        let user = action.payload.data;
        localStorage.setItem('token', user.token)
        return {
          ...state,
          token: user.token,
        };
      } else {
        return { ...state }
      }
    }
    case types.GET_USER: {
      if (
        action.payload &&
        action.payload.data &&
        action.payload.status === 200
      ) {
        let user = action.payload.data;
        localStorage.setItem('user', JSON.stringify(user))
        return {
          ...state,
          address: {
            zipcode: user.address.zipcode,
            street: user.address.street,
            state: user.address.state,
            city: user.address.city,
          },
          stripeCustomerId: user.stripeCustomerId,
          apponentTrack: user.apponentTrack,
          emailVerified: user.emailVerified,
          countryId: user.countryId || '',
          myCountry: state.countries?.filter(item => item.code === user.countryId)[0],
          userRoleId: user.userRoleId,
          modified: user.modified,
          userRole: user.userRole,
          created: user.created,
          gender: user.gender,
          status: user.status,
          fname: user.fname,
          email: user.email,
          image: user.image,
          lname: user.lname,
          bio: user.bio,
          dob: user.dob,
          hcp: user.hcp,
          id: user.id,
        };
      } else {
        return { ...state }
      }
    }
    case types.USER_SCORE: {
      if (
        action.payload &&
        action.payload.data &&
        action.payload.status === 200
      ) {
        const details = action.payload.data
        return {
          ...state,
          hcp: details.hcp,
          topEight: details.topEight,
          prizeMoney: details.prizeMoney,
          winnerCount: details.winnerCount,

        };
      } else {
        return { ...state }
      }
    }
    case types.LOGIN: {
      if (
        action.payload &&
        action.payload.data &&
        action.payload.status === 200
      ) {
        let user = action.payload.data;
        localStorage.setItem('token', user.token)
        return {
          ...state,
          token: user.token,
        };
      } else {
        return { ...state }
      }
    }
    case types.SOCIAL_LOGIN: {
      if (
        action.payload &&
        action.payload.data &&
        action.payload.status === 200
      ) {
        let user = action.payload.data;
        localStorage.setItem('token', user.token)
        return {
          ...state,
          token: user.token
        };
      } else {
        return { ...state }
      }
    }
    case types.TERMS: {
      if (action.payload && action.payload.status === 200) {
        let terms = action.payload.data;
        return {
          ...state,
          terms
        }
      } else {
        return { ...state }
      }
    }
    case types.USER_TERMS: {
      if (action.payload && action.payload.status === 200) {
        let userterms = action.payload.data;
        return {
          ...state,
          userterms
        }
      } else {
        return { ...state }
      }
    }
    case types.ALL_TERMS: {
      if (action.payload && action.payload.status === 200) {
        return {
          ...state,
        }
      } else {
        return { ...state }
      }
    }
    case types.FAQ: {
      if (action.payload && action.payload.status === 200) {
        let faq = action.payload;
        return {
          ...state,
          faq: faq
        }
      } else {
        return { ...state }
      }
    }
    case types.FAQ_DETAIL: {
      if (action.payload && action.payload.status === 200) {
        let faq = action.payload;
        return {
          ...state,
          faqDetail: faq
        }
      } else {
        return { ...state }
      }
    }
    case types.ACCEPT_TERMS: {
      if (action.payload && action.payload.status === 200) {
        return {
          ...state,
        }
      } else {
        return { ...state }
      }
    }
    case types.FORGET_PASS: {
      return { ...state }
    }
    case types.RESET_PASS: {
      if (action.payload && action.payload.status === 200) {
        let token = action?.payload?.data?.token
        localStorage.setItem('token', token)
        return { ...state, token }
      } else {
        return { ...state }
      }
    }
    case types.CHANGE_PASSWORD: {
      return { ...state }
    }
    case types.EMAIL_VERIFY: {
      return { ...state }
    }
    case types.LOGOUT: {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('countries');
      localStorage.removeItem('courseList');
      localStorage.removeItem('tournamentTypes');
      localStorage.removeItem('ranking');
      localStorage.removeItem('clubList');
      localStorage.removeItem('facilitiesList');
      localStorage.removeItem('trackList');
      localStorage.removeItem('sdkVersion');
      localStorage.removeItem('userId');
      localStorage.removeItem('userInitAlready');
      localStorage.removeItem('lastUserUpdate');
      localStorage.removeItem('sentToServer');
      localStorage.removeItem('pushAccepted');
      return { ...initialState }
    }
    case types.LANGUAGE_CODE: {
      if (action.payload && action.payload.languageCode) {
        let data = action.payload.languageCode;
        return {
          ...state,
          languageCode: data
        }
      } else {
        return { ...state }
      }
    }
    case types.COUNTRIES: {
      if (action.payload &&
        action.payload.data &&
        action.payload.status === 200) {
        let countries = action.payload.data;
        let countriesList = [];
        countries.map((country) => {
          countriesList.push({ Name: country.name, code: country.id, currency: country.currency, cc: country.code, language: country.language })
        })
        let newCountryList = JSON.stringify(countriesList)
        localStorage.setItem('countries', newCountryList)
        return {
          ...state,
          countries: countriesList,

        }
      } else {
        return { ...state }
      }
    }
    case types.RESUME_COUNTRIES: {
      if (action?.payload?.data && action?.payload?.status === 200) {
        let countries = action.payload.data
        return {
          ...state,
          countries,
        }
      } else {
        return { ...state }
      }
    }
    case types.RESUME_TOKEN: {
      if (action?.payload?.data && action?.payload?.status === 200) {
        let token = action.payload.data
        return {
          ...state,
          token: token,
        }
      } else {
        return { ...state }
      }
    }
    default: {
      return { ...state };
    }
  }
};

export default reducer;
