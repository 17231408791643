import axios from 'axios';
import store from '../store/configureStore';
import Config from '../config';

const instance = axios.create({
  baseURL: Config.SERVER_HOST,
  headers: {
    'Content-Type': 'application/json',
    'X-Gravitee-API-Key': Config.XGraviteeAPIKey
  },
  timeout: 1000000,
});

store.subscribe(() => {
  var token = localStorage.getItem('token');
  if (token) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
});

const getToken = () => {
  var localToken = localStorage.getItem('token');
  return localToken;
};



// export const API = {
const userApi = {
  registerUser: data => {
    return instance
      .post(Config.USER_HOST, data)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  checkEmailAvailability: email => {
    return instance
      .get(`${Config.USER_HOST}/count?where=${JSON.stringify({ "email": email })}`)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  login: data => {
    return instance
      .post(Config.USER_LOGIN, data)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  forgetPass: data => {
    return instance
      .post(Config.FORGET_PASS, data)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  changePassword: async data => {
    await getToken();
    return instance
      .put(Config.CHANGE_PASSWORD, data)
      .then(res => { return res; })
      .catch(res => { return res; });
  },
  verifyEmail: data => {
    return instance
      .post(Config.EMAIL_VERIFY, data)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  socialLogin: data => {
    return instance
      .post(Config.SOCIAL_LOGIN, data)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  resetPass: data => {
    return instance
      .post(Config.RESET_PASS, data)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  getDeepLink: (data) => {
    return axios.post(Config.DEEPLINK, data, { headers: { 'appApiKey': `${Config.API_KEY}` } })
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  getUserData: () => {
    var token = localStorage.getItem('token');
    return axios.get(Config.GET_USER, { headers: { 'Authorization': `Bearer ${token}` } })
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  getUserScore: () => {
    var token = localStorage.getItem('token');
    return axios.get(Config.USER_SCORE, { headers: { 'Authorization': `Bearer ${token}` } })
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  updateUser: async (data) => {
    let userId = data.id;
    delete data.id
    return instance
      .patch(`${Config.USER_HOST}/${userId}`, data)
      .then(res => {
        res.updatedUser = data;
        return res;
      })
      .catch(err => { return err; });
  },
  uploadImage: file => {
    return axios.post(Config.UPLOAD_IMAGE, file, { headers: { 'Content-Type': 'multipart/form-data', } })
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  updateAppgainUser: async (data) => {
    return instance
      .post(`${Config.APPGAIN_ID}`, data)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  signInTerms: async (type, slug) => {
    return instance
      .get(`${Config.TERMS}/${type}/${slug}`)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  userTerms: async (type, slug) => {
    return instance
      .get(`${Config.USER_TERMS}/${type}/${slug}`)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  allTerms: async (slug) => {
    return instance
      .get(`${Config.ALL_TERMS}/${slug}`)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  acceptTerms: async (data) => {
    return instance
      .post(`${Config.ACCEPT_TERMS}`, data)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  getFaq: (data) => {
    const filterObj = {
      "where": {
        "question": {
          "like": `${data}`,
          "options": "i"
        }
      },
      "include": [
        {
          "relation": "category"
        }
      ]
    }
    const filter = JSON.stringify(filterObj);
    return instance
      .get(`${Config.FAQ}?filter=${filter}`)
      .then(res => { return res; })
      .catch(err => { return err; });
  },

  faqDetail: (data) => {
    return instance
      .get(`${Config.FAQ}/${data}`)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
}
const tournamentApi = {
  allPrizeUpload: data => {
    return instance
      .post(`${Config.ALL_PRIZE_UPLOAD}`, data)
      .then(res => { return res })
      .catch(err => { return err })
  },
  saveMyTournament: data => {
    return instance
      .post(`${Config.SAVE_MY_TOURNAMENT}`, data)
      .then(res => { return res })
      .catch(err => { return err })
  },
  updateTournament: (id, data) => {
    return instance
      .patch(`${Config.TOURNAMENTS}/${id}`, data)
      .then(res => { return res })
      .catch(err => { return err })
  },
  updateMyTournament: (id, data) => {
    return instance
      .patch(`${Config.UPDATE_MY_TOURNAMENT}/${id}`, data)
      .then(res => { return res })
      .catch(err => { return err })
  },
  uploadTournament: (data) => {
    return instance
      .post(`${Config.UPLOAD_TOURNAMENTS}`, data)
      .then(res => { return res })
      .catch(err => { return err })
  },
  getUserReward: data => {
    return instance
      .get(`${Config.USER_REWARD}/${data}`)
      .then(res => { return res })
      .catch(err => { return err });
  },
  getUserPrize: data => {
    return instance
      .get(Config.USER_PRIZE)
      .then(res => { return res })
      .catch(err => { return err });
  },
  addInWaiting: data => {
    return instance
      .post(Config.WAITING, data)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  addTournament: data => {
    return instance
      .post(Config.TOURNAMENTS, data)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  registrationState: data => {
    return instance
      .post(Config.REGISTRATION_STATE, data)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  validateRegister: async (matchId) => {
    return instance
      .get(`${Config.REGISTRATIONS_VALIDATION}/${matchId}`)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  getTournamentsPlayer: async (data) => {
    await getToken();
    return instance
      .get(`${Config.PLAYER_TOURNAMENT}/${data}`)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  getPlayedPlayers: async (data) => {
    await getToken();
    return instance
      .get(`${Config.PLAYED_PLAYER}/${data}`)
      .then(res => { return res; })

      .catch(err => { return err; });
  },
  getTournamentPrizes: async (id) => {
    await getToken();
    return instance
      .get(`${Config.TOURNAMENT_PRIZES}/${id}`)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  getTournamentList: item => {
    const filerObject = {
      "where": {
        "countryId": item
      },
    };
    const filter = JSON.stringify(filerObject)
    return instance
      .get(`${Config.TOURNAMENTS}/list`)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  getMyDraftTournamentList: (filter) => {
    return instance
      .post(`${Config.MY_DRAFT_TOURNAMENT}`, filter)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  getDraftTournamentDetails: (id) => {
    return instance
      .get(`${Config.DRAFT_TOURNAMENT_DETAILS}/${id}`)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  getMyPublishTournamentList: (filter) => {
    return instance
      .get(`${Config.MY_PUBLISH_TOURNAMENT}?filter=${filter}`)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  getPlayedTournamentList: async () => {
    await getToken()
    return instance
      .get(`${Config.PLAYED_TOURNAMENTS}/played`)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  getTournamentDetails: data => {
    return instance
      .get(`${Config.TOURNAMENTS}/details/${data}`)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  getTournamentPlayList: async data => {
    await getToken();
    return instance
      .get(Config.TOURNAMENT_PLAY_LIST)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  getTournamentTypes: () => {
    return instance
      .get(Config.TOURNAMENTS_TYPES)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  getFacilities: item => {
    const filerObject = {
      "where": {
        "clubId": item
      }
    };
    const filter = JSON.stringify(filerObject);
    return instance
      .get(`${Config.FACILITIES}?filter=${filter}`)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  getRankings: () => {
    return instance
      .get(Config.LEAGUE_RANKS)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  getClubs: item => {
    const filerObject = {
      "where": {
        "countryId": item
      }
    };
    const filter = JSON.stringify(filerObject);
    return instance
      .get(`${Config.CLUB}?filter=${filter}`)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  getCourse: () => {
    return instance
      .get(Config.COURSE)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  getTrack: () => {
    return instance
      .get(Config.TRACK)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  getRoundsDetailsForUser: async (tournamentId) => {
    await getToken()
    return instance
      .get(`${Config.ROUNDS}/user/${tournamentId}`)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  cancelParticipation: data => {
    return instance
      .post(`${Config.CANCEL_PARTICIPATION}/${data}`)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
}
const paymentApi = {
  stripe: data => {
    return instance
      .post(Config.STRIPE, data)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  getTransactionHistory: () => {
    return instance
      .get(Config.TRANSACTION_HISTORY)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  addCreditCard: data => {
    return instance
      .post(Config.ADD_CREDIT_CARD, data)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  getCreditCardList: () => {
    return instance
      .get(Config.CREDIT_CARD_LIST)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  deleteCreditCard: id => {
    return instance
      .delete(`${Config.DELETE_CREDIT_CARD}/${id}`)
      .then(res => {
        res.deletedId = id
        return res;
      })
      .catch(err => { return err; });
  },
  tournamentPayment: data => {
    return instance
      .post(Config.TOURNAMENT_PAYMNET, data)
      .then(res => { return res; })
      .catch(err => {
        return err;
      });
  },
}
const countryApi = {
  getCountries: () => {
    return instance
      .get(Config.COUNTRIES)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
}
const matchesApi = {
  getMatchDetails: (matchId) => {
    return instance
      .get(`${Config.MATCHES}/${matchId}`)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  getMatchScore: (matchId) => {
    return instance
      .get(`${Config.MATCHESSCORES}/${matchId}`)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  getPlayedScore: (matchId) => {
    return instance
      .get(`${Config.PLAYED_SCORES}/${matchId}`)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  finishRound: (matchId) => {
    return instance
      .post(`${Config.MATCHES}/finish/${matchId}`)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  finishRoundWithPlayOff: (data) => {
    return instance
      .post(`${Config.MATCHES}/play-off`, data)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  tournamentSearch: (data) => {
    return instance
      .post(`${Config.TOURNAMENTS}/search`, data)
      .then(res => { return res; })
      .catch(res => { return res; })
  }
}
const scoreApi = {
  saveScore: (holeScore) => {
    return instance
      .post(`${Config.SCORES}/track`, holeScore)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
  getMatchScoreDetails: (id) => {
    return instance
      .get(`${Config.SCORES}/match/${id}`)
      .then(res => { return res; })
      .catch(err => { return err; });
  },
}

export {
  userApi,
  tournamentApi,
  paymentApi,
  countryApi,
  matchesApi,
  scoreApi
}
// };

// module.exports = API;
