import tw from 'tailwind-styled-components';
import './newsBoxStyle.css';
import googleLogo from '../../assets/images/gmail.png';

const NewsBox = () => {
  return (
    <OuterView>
      <Img src={'https://images.unsplash.com/photo-1535131749006-b7f58c99034b'} />
      <div className='py-[16px]'>
        <Title>Martin Laird came away victorious in a three-way playoff to win the…</Title>
      </div>
      <Footer>
        <Footer>
          <Icon src={googleLogo} />
          <p fontFamily='Source Sans Pro, sans-serif' className=' font-medium text-[15px] text-gray51'>golf.com</p>
        </Footer>
        <Time>17 min ago</Time>
      </Footer>
    </OuterView>
  )
}

const OuterView = tw.div`
mt-6
w-full
h-auto
border 
rounded-[20px]
outer-box
px-[18px]
py-[16px]
`

const Img = tw.img`
rounded-[5px]
h-[171px]
w-full
object-cover
`

const Title = tw.text`
text-black 
text-lg 
news-title
`

const Footer = tw.div`
flex 
flex-row 
flex-wrap 
items-center 
justify-between
`

const Icon = tw.img`
h-[37px] 
w-[37px] 
border 
rounded-full 
outer-box 
mr-[12px]
`
const Time = tw.text`
text-gray-500 
time-text
time-wrapping
`


export default NewsBox