import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import tw from 'tailwind-styled-components/';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Text } from '@components/';
import { OutsideAlerter } from '@utils';
import logo from '@assets/images/s6mLogo/s6MLogo3x.png';
import * as action from '@store/user/actions';
import { useSpring, animated } from '@react-spring/web';
import UserImage from '@assets/images/profile.jpeg';

const getIsMobile = () => window.innerWidth <= 1024

const Header = ({ profileImage, userName, logout, userRoleSlug }) => {
  const [state, setState] = useState({
    open: false,
    menuOpen: false,
    active: 'dashboard',
    isIPad: getIsMobile(),
  });

  useEffect(() => {
    const onResize = () => {
      state.isIPad !== getIsMobile() && setState(prev => ({ ...prev, isIPad: getIsMobile() }))
    }

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    }
  }, [state.isIPad]);


  const rotateStyle = useSpring({ rotate: state.open ? 180 : 0 })

  const firstBar = useSpring({ rotate: state.menuOpen ? -45 : 0, marginBottom: state.menuOpen ? -3 : 5 })
  const secondBar = useSpring({ opacity: state.menuOpen ? 0 : 1, marginTop: state.menuOpen ? 0 : 5, marginBottom: state.menuOpen ? 0 : 5, translateX: state.menuOpen ? -10 : 0 })
  const thirdBar = useSpring({ rotate: state.menuOpen ? 45 : 0, marginTop: state.menuOpen ? -3 : 5 })

  const changeColorAnim = useSpring({ backgroundColor: state.menuOpen ? 'rgba(0, 0, 0, 0.2)' : 'transparent' })
  const menuStyleAnim = useSpring({ translateY: state.menuOpen ? 0 : -400, opacity: state.menuOpen ? 1 : 0 })

  const dropDownStyle = useSpring({
    opacity: state.open ? 1 : 0,
    display: state.open ? 'block' : 'none',
    translateY: state.open ? 0 : -20
  })

  const changePath = useSpring({
    translateX: state.active === 'dashboard'
      ? 0
      : state.active === 'tournamentHistory'
        ? state.isIPad
          ? 90
          : 130
        : 0,
    width: state.active === 'tournamentHistory'
      ? state.isIPad
        ? 160
        : 175
      : 95,
    opacity: state.active === '' ? 0 : 1
  })

  const navigate = useNavigate();
  const location = useLocation();
  const screen = location.pathname

  const goToPath = (value) => {
    setState(prev => ({ ...prev, open: !state.open }))
    navigate(value);
  }

  const checkActive = (props) => {
    var pathName = location.pathname?.split('/')
    var path = '/' + pathName[1]
    var checkPath = (path === '/tournament' || path === '/tournament-payment' || path === '/next-tournament' || path === '/my-tournament') ? '/' : path
    return props === checkPath
  }

  useEffect(() => {
    var pathName = location.pathname?.split('/')
    var path = '/' + pathName[1]
    var checkPath = (path === '/tournament' || path === '/tournament-payment' || path === '/next-tournament' || path === '/my-tournament') ? '/' : path
    var origPath = checkPath === '/' ? 'dashboard' : checkPath === '/settings' ? '' : 'tournamentHistory'
    setState(prev => ({ ...prev, active: origPath }))
  }, [location.pathname])


  const toNavigate = (path) => {
    setState(prev => ({ ...prev, menuOpen: false }))
    navigate(path)
  }

  const MobileMenu = () => {
    return (
      <div>
        {state.menuOpen && <animated.div className={MobileMenuContainer} style={changeColorAnim}>
          <OutsideAlerter close={() => setState(prev => ({ ...prev, menuOpen: false }))}>
            <animated.div
              className={MenuContainer}
              style={{ ...menuStyleAnim, boxShadow: 'rgb(0 0 0 / 6%) -15px 0px 20px 0px' }}
            >
              <div className="w-full h-full relative ">
                <div
                  className={
                    'bg-white rounded-[8px] flex flex-col px-[25px] outline-none shadow-3xl'
                  }
                >
                  <MobileItem
                    title={'Dashboard'}
                    onClick={() => toNavigate('/')}
                    active={screen === '/'}
                  />
                  <MobileItem
                    title={userRoleSlug === 'club-member' ? 'Payout' : `Tournament's history`}
                    onClick={() => toNavigate('/tournament-history')}
                    active={screen === '/tournament-history'}
                  />
                  <MobileItem
                    title={'Manage Account'}
                    onClick={() => toNavigate(userRoleSlug === 'club-member' ? '/club-settings/manageAccount' : '/settings/manageAccount')}
                  />
                  <MobileItem
                    title={'Payment & Payouts'}
                    onClick={() => toNavigate(userRoleSlug === 'club-member' ? '/club-settings/payments' : '/settings/payments')}
                  />
                  <MobileItem
                    title={'Email Notification'}
                    onClick={() => toNavigate(userRoleSlug === 'club-member' ? '/club-settings/notification' : '/settings/notification')}
                  />
                  <MobileItem title={'Faq'} onClick={() => toNavigate(userRoleSlug === 'club-member' ? '/club-settings/faq' : '/settings/faq')} />
                  <MobileItem title={'Logout'} onClick={logout} />
                </div>
              </div>
            </animated.div>
          </OutsideAlerter>
        </animated.div>}
      </div>
    );
  };

  const MenuItem = ({ title, onClick }) => {
    let className =
      title === 'Logout'
        ? ''
        : 'border border-0 border-b-[1px] border-b-[rgba(34,35,49, 0.07)]';
    return (
      <div className={className}>
        <Text>
          <DropdownButton onClick={onClick} >
            {title}
          </DropdownButton>
        </Text>
      </div>
    );
  };

  const MobileItem = ({ title, onClick, active }) => {
    let className = active
      ? 'border border-0 border-b-[2px] text-fbButtonColor border-b-fbButtonColor'
      : title === 'Logout'
        ? ''
        : 'border border-0 border-b-[1px] border-b-[rgba(34,35,49, 0.07)]';
    return (
      <div className={`${className} w-full`}>
        <DropdownButton onClick={onClick}>{title}</DropdownButton>
      </div>
    );
  };

  const DropDown = () => {
    return (
      <div className="relative inline-block text-left">
        <div className={'flex items-center cursor-pointer overflow-hidden'} >
          <button className='flex items-center' onClick={() => setState(prev => ({ ...prev, open: !prev.open }))}>
            <ProfileImg alt={'Profile'} src={profileImage || UserImage} />
            <Text
              className={
                'mx-[10px] w-[125px] text-center whitespace-nowrap overflow-hidden overflow-ellipsis'
              }
            >
              {userName}
            </Text>
            <animated.div style={rotateStyle}>
              <ChevronDownIcon className={`w-5 h-5`} aria-hidden="true" />
            </animated.div>
          </button>
        </div>
        {state.open && <animated.div style={dropDownStyle}>
          <div
            className={
              'absolute w-[219px] bg-white mt-[20px] rounded-[8px] flex flex-col px-[25px] outline-none shadow-3xl'
            }
          >
            <OutsideAlerter close={() => setState(prev => ({ ...prev, open: false }))}>
              <MenuItem
                title={'Manage Account'}
                onClick={() => goToPath(userRoleSlug === 'club-member' ? '/club-settings/manageAccount' : '/settings/manageAccount')}
              />
              <MenuItem
                title={'Payment & Payouts'}
                onClick={() => goToPath(userRoleSlug === 'club-member' ? '/club-settings/payments' : '/settings/payments')}
              />
              <MenuItem
                title={'Email Notification'}
                onClick={() => goToPath(userRoleSlug === 'club-member' ? '/club-settings/notification' : '/settings/notification')}
              />
              <MenuItem title={'Faq'} onClick={() => goToPath(userRoleSlug === 'club-member' ? '/club-settings/faq' : '/settings/faq')} />
              <MenuItem title={'Logout'} onClick={logout} />
            </OutsideAlerter>
          </div >
        </animated.div>}
      </div >
    );
  };

  const MenuIconNew = () => {
    let hr = 'h-[3px] bg-black rounded-full'
    return (
      <button className='w-[25px] h-[30px] my-[auto] lg:hidden md:hidden cursor-pointer' onClick={() => setState(prev => ({ ...prev, menuOpen: !state.menuOpen }))} >
        <animated.div style={firstBar} className={`${hr}`} />
        <animated.div style={secondBar} className={`${hr} my-[5px]`} />
        <animated.div style={thirdBar} className={`${hr}`} />
      </button>
    );
  }

  const width = userRoleSlug === 'club-member' ? 'w-[200px] lg:w-[250px]' : `w-[250px] lg:w-[300px]`

  return (
    <>
      <HeaderContainer>
        <div className="lg:w-[20%]">
          <LinkTag onClick={() => navigate('/')}>
            <Img src={logo} />
          </LinkTag>
        </div>
        <div className='h-[100%] flex-col relative'>
          <div className={`${width} h-[100%] justify-between lg:flex md:flex hidden`}>
            <LinkTag
              style={{ transition: '.5s' }}
              active={checkActive('/')}
              onClick={() => navigate('/')}
            >
              Dashboard
            </LinkTag>
            <LinkTag
              style={{ transition: '.5s' }}
              active={checkActive('/tournament-history')}
              onClick={() => navigate('/tournament-history')}
            >
              {userRoleSlug === 'club-member' ? 'Payout' : `Tournament's history`}
            </LinkTag>
          </div>
          <animated.div style={{ ...changePath }} className='w-[95px] h-[3px] rounded-full bg-fbButtonColor absolute lg:flex md:flex hidden' />
        </div>
        <MenuIconNew />
        <div className={`items-center w-[20%] justify-end hidden md:flex`}>
          <Vr />
          <DropDown />
        </div>
      </HeaderContainer>
      <MobileMenu />
    </>
  );
};

const HeaderContainer = tw.div`
fixed
top-0
bg-white/75
shadow-headerShadow
backdrop-blur-md
w-full
flex
justify-between
lg:px-[10%]
px-[5%]
h-[65px]
z-50
`;
const Img = tw.img`
w-[78px]
h-auto
object-center 
object-cover
`;
const Vr = tw.div`
min-w-[1px]
h-[75%]
mx-5
bg-[rgba(34,35,49,0.07)]
`;

const LinkTag = tw.button`
${(p) => p.active ? 'border-fbButtonColor text-fbButtonColor' : 'border-[transparent]'}
transition-[5s]
h-full
px-2
lg:text-[13px]
lg:tracking-[0.14px]
text-[12px]
tracking-[0.13px]
font-medium
uppercase
`;
// border-b-[3px]

const ProfileImg = tw.img`
min-w-[45px] 
max-w-[45px] 
min-h-[45px] 
max-h-[45px] 
object-cover 
rounded-full 
border-[1px] 
border-fbButtonColor
`;

const DropdownButton = tw.button`
${(p) => (p.active ? 'text-fbButtonColor' : null)}
hover:text-fbButtonColor
py-[15px]
px-2
overflow-hidden
overflow-ellipsis
text-left
w-full
`;

const MobileMenuContainer = `
lg:hidden
md:hidden
fixed
top-[65px]
overflow-hidden
h-screen
w-screen
z-50
`;

const MenuContainer = `
absolute
top-4
rounded-[8px]
right-5
left-5
shadow-2xl
bg-white`;

const mapStateToProps = (state) => {
  return {
    profileImage: state.user.image,
    userName: `${state.user.fname || 'Super'} ${state.user.lname || 'Match Play'}`,
    userRoleSlug: state.user.userRole.slug || 'user'
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: data => dispatch(action.logout({ data })),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Header);
