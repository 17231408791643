import tw from 'tailwind-styled-components/';
import { Text } from '@components/';
import CalenderDark from '@assets/images/calendarIcon.png';
import CalenderLight from '@assets/images/Calender.png';
import AlarmDark from '@assets/images/alarmIcon.png';
import LocationDark from '@assets/images/locationIcon.png';
import Logo from '@assets/images/s6mLogo/s6MLogo.png';
import './style.css';

const TournamentCard = ({ className, date, time, location, onClick, buttonTitle, buttonIcon, tournamentName, buttonDisabled }) => {

  const RegisterButton = () => {
    return (
      <button
        disabled={buttonDisabled}
        onClick={onClick}
        className={`${buttonDisabled ? 'bg-fbButtonColorDisable' : 'bg-fbButtonColor'} text-white text-[12px] uppercase px-[34px] py-[12px] rounded-full ml-8 mt-5 font-bold flex justify-center items-center`}
        style={{ fontFamily: 'Source Sans Pro, sans-serif' }}
      >
        {buttonIcon === 'calender' ? <img alt='calenderIcon' src={CalenderLight} className="w-[15px] h-[15px] mr-[10px] object-contain" /> : null}
        {buttonTitle}
      </button>
    );
  };


  const Details = ({ src, title }) => {
    return (
      <div className="flex w-full items-start mb-[15px]">
        <img alt='calenderIcon' src={src} className="w-[15px] h-[15px] mr-[15px] object-contain" />
        <Text className={'whitespace-pre-line items-start'}>{title}</Text>
      </div>
    );
  };

  return (
    <TournamentContainer
      className={`${className} select-none overflow-hidden shadow-3xl`}
    >
      <div className="flex justify-between">
        <div>
          {tournamentName && <p className='font-bold capitalize mb-[30px] text-[19px] tracking-[0.23px]'>{tournamentName}</p>}
          <Details src={CalenderDark} title={date} />
          {time && <Details src={AlarmDark} title={time} />}
          <Details
            src={LocationDark}
            title={location}
          />
          {buttonTitle && <RegisterButton />}
        </div>
        <img
          alt='calenderIcon'
          src={Logo}
          className="lg:w-[70px] lg:h-[60px] w-[60px] h-[50px] object-contain"
        />
      </div>
    </TournamentContainer>
  );
};

const TournamentContainer = tw.div`
md:min-w-[366px]
min-w-[90%]
bg-white
p-7
rounded-[20px]
`;

export default TournamentCard;