const PREFIX = `@@user`;

export const TOURNAMENTS = `${PREFIX}/TOURNAMENTS`;
export const PLAYED_TOURNAMENTS = `${PREFIX}/PLAYED_TOURNAMENTS`;
export const TOURNAMENT_DETAILS = `${PREFIX}/TOURNAMENT_DETAILS`;
export const PLAYER_TOURNAMENT = `${PREFIX}/PLAYER_TOURNAMENT`;
export const PLAYED_PLAYER = `${PREFIX}/PLAYED_PLAYER`;
export const TOURNAMENT_PLAY_LIST = `${PREFIX}/TOURNAMENT_PLAY_LIST`;
export const REGISTRATIONS_VALIDATION = `${PREFIX}/REGISTRATIONS_VALIDATION`;
export const REGISTRATION_STATE = `${PREFIX}/REGISTRATION_STATE`;
export const LOCAL_TOURNAMENTS = `${PREFIX}/LOCAL_TOURNAMENTS`;
export const ADD_LOCAL_TOURNAMENTS = `${PREFIX}/ADD_LOCAL_TOURNAMENTS`;
export const ADD_MY_TOURNAMENTS = `${PREFIX}/ADD_MY_TOURNAMENTS`;
export const BLANK_TOURNAMENT_DETAILS = `${PREFIX}/BLANK_TOURNAMENT_DETAILS`;
export const TOURNAMENT_PRIZES = `${PREFIX}/TOURNAMENT_PRIZES`;
export const TOURNAMENT_TYPES = `${PREFIX}/TOURNAMENT_TYPES`;
export const RESUME_TOURNAMENT_TYPES = `${PREFIX}/RESUME_TOURNAMENT_TYPES`;
export const DELETE_TOURNAMENT = `${PREFIX}/DELETE_TOURNAMENTS`;
export const LEAGUE_RANKS = `${PREFIX}/LEAGUE_RANKS`;
export const RESUME_LEAGUE_RANKS = `${PREFIX}/RESUME_LEAGUE_RANKS`;
export const RESUME_CLUB = `${PREFIX}/RESUME_CLUB`;
export const RESUME_TRACK = `${PREFIX}/RESUME_TRACK`;
export const RESUME_COURSE = `${PREFIX}/RESUME_COURSE`;
export const CLUB = `${PREFIX}/CLUB`;
export const TRACK = `${PREFIX}/TRACK`;
export const COURSE = `${PREFIX}/COURSE`;
export const RESUME_FACILITIES = `${PREFIX}/RESUME_FACILITIES`;
export const FACILITIES = `${PREFIX}/FACILITIES`;
export const CANCEL_PARTICIPATION = `${PREFIX}/CANCEL_PARTICIPATION`;
export const MY_DRAFT_TOURNAMENT = `${PREFIX}/MY_DRAFT_TOURNAMENT`;
export const MY_PUBLISH_TOURNAMENT = `${PREFIX}/MY_PUBLISH_TOURNAMENT`;
export const SAVE_MY_TOURNAMENT = `${PREFIX}/SAVE_MY_TOURNAMENT,`;
export const UPDATE_MY_TOURNAMENT = `${PREFIX}/UPDATE_MY_TOURNAMENT,`;
export const UPLOAD_TOURNAMENTS = `${PREFIX}/UPLOAD_TOURNAMENTS,`;

export const GET_ROUNDS_DETAILS_FOR_USER = `${PREFIX}/GET_ROUNDS_DETAILS_FOR_USER`;
export const GET_MATCH_DETAILS_BY_ID = `${PREFIX}/GET_MATCH_DETAILS_BY_ID`;
export const MATCHESSCORES = `${PREFIX}/MATCHESSCORES`;
export const PLAYED_SCORES = `${PREFIX}/PLAYED_SCORES`;
export const MATCH_SCORE_DETAILS = `${PREFIX}/MATCH_SCORE_DETAILS`;
export const SAVE_SCORE = `${PREFIX}/SAVE_SCORE`;
export const FINISH_MATCH = `${PREFIX}/FINISH_MATCH`;
export const FINISH_MATCH_WITH_PLAYOFF = `${PREFIX}/FINISH_MATCH_WITH_PLAYOFF`;
export const UPDATE_ROUNDS = `${PREFIX}/UPDATE_ROUNDS`;
export const UPDATE_PLAY_DATA = `${PREFIX}/UPDATE_PLAY_DATA`;
export const UPDATE_TOURNAMENT = `${PREFIX}/UPDATE_TOURNAMENT`;
export const WAITING = `${PREFIX}/WAITING`;
export const REMOVE_MATCH_DETAILS = `${PREFIX}/REMOVE_MATCH_DETAILS`;
export const USER_PRIZE = `${PREFIX}/USER_PRIZE`;
export const USER_REWARD = `${PREFIX}/USER_REWARD`;
export const TOURNAMENT_SEARCH = `${PREFIX}/TOURNAMENT_SEARCH`;
export const TOURNAMENTS_TYPES = `${PREFIX}/TOURNAMENTS_TYPES`;
export const DRAFT_TOURNAMENT_DETAILS = `${PREFIX}/DRAFT_TOURNAMENT_DETAILS`;
export const ALL_PRIZE_UPLOAD = `${PREFIX}/ALL_PRIZE_UPLOAD`;
