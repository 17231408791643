import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// import { firebase } from ".";

const firebaseConfig = {
  apiKey: "AIzaSyDho82zU7gh3FWFEcDFVIk3RozvICIjf5c",
  authDomain: "s6m-webapp.firebaseapp.com",
  projectId: "s6m-webapp",
  storageBucket: "s6m-webapp.appspot.com",
  messagingSenderId: "143447742610",
  appId: "1:143447742610:web:597e05f93add7ac17b6e4b",
  measurementId: "G-G9468LQGJD"
};

const app = firebase.app.length && initializeApp(firebaseConfig)
// const app = initializeApp(firebaseConfig)
getAnalytics(app);

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

export default app;

