const PREFIX = `@@user`;

export const LOGOUT = `${PREFIX}/LOGOUT`;
export const LOGIN = `${PREFIX}/LOGIN`;
export const SOCIAL_LOGIN = `${PREFIX}/SOCIAL_LOGIN`;
export const UPDATE_USER = `${PREFIX}/UPDATE_USER`;
export const FORGET_PASS = `${PREFIX}/FORGET_PASS`;
export const EMAIL_VERIFY = `${PREFIX}/EMAIL_VERIFY`;
export const UPDATE_NETWORK_STATUS = `${PREFIX}/UPDATE_NETWORK_STATUS`;
export const REGISTER_USER = `${PREFIX}/REGISTER_USER`;
export const USER_DETAILS = `${PREFIX}/USER_DETAILS`;
export const GET_USER = `${PREFIX}/GET_USER`;
export const USER_SCORE = `${PREFIX}/USER_SCORE`;
export const UPLOAD_IMAGE = `${PREFIX}/UPLOAD_IMAGE`;
export const UPDATE_TOKEN = `${PREFIX}/UPDATE_TOKEN`;
export const EMAIL_CHECK = `${PREFIX}/EMAIL_CHECK`;
export const LANGUAGE_CODE = `${PREFIX}/LANGUAGE_CODE`;
export const COUNTRIES = `${PREFIX}/COUNTRIES`;
export const DEEPLINK = `${PREFIX}/DEEPLINK`;
export const APPGAIN_ID = `${PREFIX}/APPGAIN_ID`;
export const TERMS = `${PREFIX}/TERMS`;
export const USER_TERMS = `${PREFIX}/USER_TERMS`;
export const ACCEPT_TERMS = `${PREFIX}/ACCEPT_TERMS`;
export const ALL_TERMS = `${PREFIX}/ALL_TERMS`;
export const FAQ = `${PREFIX}/FAQ`;
export const FAQ_DETAIL = `${PREFIX}/FAQ_DETAIL`;
export const CHANGE_PASSWORD = `${PREFIX}/CHANGE_PASSWORD`;
export const RESET_PASS = `${PREFIX}/RESET_PASS`;

export const RESUME_COUNTRIES = `${PREFIX}/RESUME_COUNTRIES`;
export const RESUME_TOKEN = `${PREFIX}/RESUME_TOKEN`;