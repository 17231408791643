import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import * as action from '@store/user/actions';
import * as tournamentAction from '@store/tournament/actions';
import { consoleBlocker, logEvent, initializeSocket, common, deregisterSocketEvent, registerSocketEvent } from '@utils';
import { DashBoardHeader, Loader, TermsModal } from "@components/";
import { firebase } from '@services';
import './App.css';
import routes from './routes';
import ClubDashboard from './containers/clubDashboard';
import Dashboard from './containers/dashboard';
import ErrorPage from './containers/errorPage';
import Login from './containers/login';

const App = (props) => {
  consoleBlocker()
  const {
    allTerms,
    userToken,
    resumeToken,
    resumeClubs,
    resumeTrack,
    getUserData,
    getUserScore,
    resumeCourse,
    resumeRanking,
    resumeFaciltiy,
    getCountryList,
    resumeCountries,
    resumeTournamentTypes
  } = props;

  const [state, setState] = useState({
    termsVisible: false,
    termsApi: false,
    isLoading: false,
    terms: null,
    privacy: null,
    userRole: ''
  })

  let token = userToken || localStorage.getItem('token');
  let countryLocal = localStorage.getItem('countries');
  let facilitiesList = localStorage.getItem('facilitiesList');
  let courses = localStorage.getItem('courseList')
  let clubs = localStorage.getItem('clubList')
  let tracks = localStorage.getItem('trackList')
  let ranking = localStorage.getItem('ranking')
  let tournamentTypes = localStorage.getItem('tournamentTypes')

  let country = JSON.parse(countryLocal);
  let facilityList = JSON.parse(facilitiesList);
  let courseList = JSON.parse(courses);
  let clubList = JSON.parse(clubs);
  let trackList = JSON.parse(tracks);
  let rankingList = JSON.parse(ranking);
  let tournamentTypesList = JSON.parse(tournamentTypes);

  useEffect(() => {
    if (country && token) {
      resumeCountries({ data: country, status: 200 });
    } else {
      getCountryList()
    }
  }, [country, token])

  useEffect(() => {
    if (token) {
      setState(prev => ({ ...prev, isLoading: true }))
      resumeToken({ data: token, status: 200 });
      getUserScore()
      getUser()
      checkAcceptance()
      resumeFaciltiy({ data: facilityList, status: 200 })
      resumeCourse({ data: courseList, status: 200 })
      resumeClubs({ data: clubList, status: 200 })
      resumeTrack({ data: trackList, status: 200 })
      resumeRanking({ data: rankingList, status: 200 })
      resumeTournamentTypes({ data: tournamentTypesList, status: 200 })
    }
  }, [token])

  const getUser = () => {
    getUserData().then(res => {
      if (res?.payload?.data && res?.payload?.status === 200) {
        let slug = res?.payload?.data?.userRole?.slug;
        setState(prev => ({ ...prev, userRole: slug, isLoading: false }))
      } else {
        setState(prev => ({ ...prev, isLoading: false }))
      }
    });
  }

  useEffect(() => {
    initializeSocket()
  }, [])

  useEffect(() => {
    registerListner(props?.userId)
    return () => {
      deregisterSocketEvent(common)
    }
  }, [props?.userId])

  const registerListner = (userId) => {
    registerSocketEvent(common, (data) => {
      let recievedData = JSON.parse(data);
      if (
        recievedData?.userId == userId
      ) {
        getUser()
      }
    });
  }

  useEffect(() => {
    if (props?.userName && props?.userEmail && Object.keys(Appgain).length > 5 && Appgain) {
      appgainInit()
    }
  }, [props?.userEmail, props?.userName, props?.userId])

  const appgainInit = () => {
    let appgainConfig = {
      projectId: process.env.REACT_APP_PROJECT_ID,
      apiKey: process.env.REACT_APP_API_KEY,
    };
    Appgain?.init(appgainConfig)
      ?.then((res) => {
        updateAppgainUser()
        console.log(res);
      })
      ?.catch((err) => {
        console.log(err);
      });
  }

  const updateAppgainUser = () => {
    let userData = {
      userEmail: props?.userEmail,
      name: props?.userName,
    };
    console.log('Appgain', userData)
    Appgain.updateUser(userData)
  }

  const checkAcceptance = () => {
    allTerms('login-auth').then(res => {
      if (res?.payload?.data && res?.payload?.status == 200) {
        let newData = []
        let terms = res?.payload?.data[0]
        let policy = res?.payload?.data[1]

        res?.payload?.data?.map(data => {
          newData.push({
            termsPolicyId: data?.currentTermsPolicy?.id,
            categoryId: data?.currentTermsPolicy?.categoryId,
            type: data?.currentTermsPolicy?.type,
            version: data?.currentTermsPolicy?.version
          })
        })

        setState(prev => ({ ...prev, termsApi: newData }))

        if (terms?.isUserAgreed && policy.isUserAgreed) {
          setState(prev => ({ ...prev, termsVisible: false, terms: terms, privacy: policy }))
        } else {
          setState(prev => ({ ...prev, termsVisible: true, terms: terms, privacy: policy }))
        }
      }
    })
  }

  const Element = ({ item }) => {
    if (state.userRole === 'club-member') {
      if (item.isPublic) {
        return token ? <Navigate replace to="/" /> : item.element
      } else {
        return (token && item.isClub) ? item.element : <Navigate replace to="/" />
      }
    } else {
      if (item.isPublic) {
        return token ? <Navigate replace to="/" /> : item.element
      } else {
        return (token && !item.isClub) ? item.element : <Navigate replace to="/" />
      }
    }
  };

  return (
    <>
      <Routes>
        {routes.map((item, index) => (
          <Route
            key={'' + index}
            path={item.path}
            exact={item.exact}
            name={item.name}
            element={
              <>
                {!item.isPublic && <DashBoardHeader />}
                <Element item={item} />
              </>
            }
          />
        ))}
        <Route exact path="/" element={
          token
            ? (state.userRole === 'club-member')
              ? <><DashBoardHeader /><ClubDashboard /></>
              : <><DashBoardHeader /><Dashboard /></>
            : <Login />
        } />
        <Route path="/error-page" element={<ErrorPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <TermsModal isOpenModal={state.termsVisible} closeModal={() => state.termsVisible && setState(prev => ({ ...prev, termsVisible: false }))} termsApi={state.termsApi} terms={state.terms} privacy={state.privacy} />
      {
        state.isLoading && <div className='bg-white fixed top-0 left-0 w-full h-full center z-[5000]'>
          <Loader loading={state.isLoading} />
        </div>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userToken: state.user.token,
    userId: state.user.id,
    userEmail: state.user.email?.toLowerCase() || '',
    userName: `${state.user?.fname} ${state.user?.lname}`,
    profile_image: state.user?.image
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    allTerms: (slug) => dispatch(action.allTerms(slug)),
    getUserScore: (data) => dispatch(action.getUserScore(data)),
    getUserData: (data) => dispatch(action.getUserData(data)),
    resumeUser: (data) => dispatch(action.resumeUser(data)),
    resumeToken: (data) => dispatch(action.resumeToken(data)),
    getCountryList: () => dispatch(action.getCountries()),
    resumeCountries: (data) => dispatch(action.resumeCountries(data)),
    resumeCourse: (data) => dispatch(tournamentAction.resumeCourse(data)),
    resumeFaciltiy: (data) => dispatch(tournamentAction.resumeFaciltiy(data)),
    resumeClubs: (data) => dispatch(tournamentAction.resumeClubs(data)),
    resumeTrack: (data) => dispatch(tournamentAction.resumeTrack(data)),
    resumeRanking: (data) => dispatch(tournamentAction.resumeRanking(data)),
    resumeTournamentTypes: (data) => dispatch(tournamentAction.resumeTournamentTypes(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
