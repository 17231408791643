import React, { useState } from 'react';
import tw from 'tailwind-styled-components/';
import moment from 'moment';
import { customHeight, customMargin } from '@utils';
import { Loader } from '..';

const OrdinalSuffix = (i) => {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + 'st';
  }
  if (j == 2 && k != 12) {
    return i + 'nd';
  }
  if (j == 3 && k != 13) {
    return i + 'rd';
  }
  return i + 'th';
};

const ScoringWidgets = ({ tournamentScore, tee, tournament }) => {

  const [state, setState] = useState({
    tee: '1',
  })

  const scoreList = state.tee === '1' ? tournamentScore?.tee1 : tournamentScore?.tee10

  let getTee = 1

  switch (tee) {
    case '1st':
      getTee = 1;
      break;
    case '10th':
      getTee = 10;
      break;
    case '1st & 10th':
      getTee = 11
      break;
  }

  const Line = ({ index, rootIndex, activePage }) => {
    return index % 2 !== 0 ? (
      <VLine
        index={index % 2 !== 0}
        rootIndex={rootIndex}
        style={{
          height: `${customHeight(rootIndex, activePage)}px`,
          marginBottom: `${customHeight(rootIndex, activePage)}px`,
        }}
      />
    ) : (
      <VLine
        index={index % 2 !== 0}
        rootIndex={rootIndex}
        style={{
          height: `${customHeight(rootIndex, activePage)}px`,
          marginTop: `${customHeight(rootIndex, activePage)}px`,
        }}
      />
    );
  };

  const Tenticles = ({ matchScores, rootIndex, index, activePage, data }) => {
    if (
      rootIndex === matchScores?.length - 1 ||
      data.winnerId === null ||
      matchScores[matchScores?.length]
    ) {
      return <></>;
    } else {
      return (
        <>
          <HLine />
          <Line index={index} activePage={activePage} rootIndex={rootIndex} />
        </>
      );
    }
  };

  const PlayerCard = ({ holeLead, playerName, playOff, playerId, holeWins, winnerId }) => {
    return (
      <div className="flex justify-between items-center my-[8px]">
        <Player style={{ fontFamily: 'Source Sans, sans-serif' }}>
          {playerName}
        </Player>
        <WinnerBox playOff={playOff} index playerId={playerId} holeWins={holeWins} winnerId={winnerId} holeLead={holeLead} />
      </div>
    );
  }

  const WinnerBox = (props) => {
    if (props?.playerId == props?.winnerId && (props?.holeWins > 0 || props?.playOff == 'playOff' || props?.playOff == 'bye')) {
      return (
        <WinnerContainer>{props?.playOff == 'playOff' ? 'PO' : props?.playOff == 'bye' ? 'BYE' : `${props?.holeWins}UP${props?.holeLead > 0 ? '/' + props?.holeLead : ''}`}</WinnerContainer>
      )
    }
    else return null
  }

  const ScoreBox = ({ item, index, rootIndex }) => {
    const firstPlayerfname = item?.playerList[0]?.user?.fname || 'Unavailable';
    const firstPlayerlname = item?.playerList[0]?.user?.lname || '';
    const secondPlayerfname = item?.playerList[1]?.user?.fname || 'Unavailable';
    const secondPlayerlname = item?.playerList[1]?.user?.lname || '';
    const firstPlayer = `${firstPlayerfname} ${firstPlayerlname}`;
    const secondPlayer = `${secondPlayerfname} ${secondPlayerlname}`;
    const firstPlayerId = item?.playerList[0]?.id;
    const secondPlayerId = item?.playerList[1]?.id;
    const winnerId = item?.winnerId;
    const holeWins = item?.holeWins;
    const playOff = item?.winningType;
    const holeLead = item?.holeLead || 0
    //renderPlayer
    return (
      <Container style={{ margin: `${customMargin(rootIndex, 0)}px 0`, }}>
        <StartSapce first={rootIndex === 0} />
        <ScoreBoxContainer>
          <PlayerCard holeLead={holeLead} playerName={firstPlayer} playOff={playOff} playerId={firstPlayerId} winnerId={winnerId} holeWins={holeWins} />
          <Hr />
          <PlayerCard holeLead={holeLead} playerName={secondPlayer} playOff={playOff} playerId={secondPlayerId} winnerId={winnerId} holeWins={holeWins} />
        </ScoreBoxContainer>
        <Tenticles
          matchScores={scoreList}
          rootIndex={rootIndex}
          index={index}
          activePage={0}
          data={item}
        />
      </Container>
    );
  };

  const ScoreStrap = ({ item, index }) => {
    let rootIndex = index;
    let holesInRound = 3
    let round = item.roundNumber
    let lastHole = holesInRound * round
    let firstHole = rootIndex == 0 ? 1 : lastHole - 2
    return (
      //FlatList
      <ScoringContainer>
        <HeadText style={{ fontFamily: 'Source Sans, sans-serif' }}>
          {OrdinalSuffix(round)} Rd. Holes {firstHole} - {lastHole}
        </HeadText>
        {item?.matches?.map((item, index) => (
          <ScoreBox item={item} index={index} rootIndex={rootIndex} />
        ))}
      </ScoringContainer>
    );
  };

  const Tee = ({ selected, value, onClick }) => {
    return (
      <TeeText active={selected} onClick={onClick}>
        {value}
      </TeeText>
    );
  }

  const Switch = ({ tee }) => {
    return (
      <SwitchContainer>
        {
          tee === 1
            ? <Tee selected={true} value={'tee 1'} onClick={() => setState(prev => ({ ...prev, tee: '1' }))} />
            : tee === 10
              ? <Tee selected={true} value={'tee 10'} onClick={() => setState(prev => ({ ...prev, tee: '10' }))} />
              : tee === 11
                ? <>
                  <Tee selected={state.tee === '1' ? true : false} value={'tee 1'} onClick={() => setState(prev => ({ ...prev, tee: '1' }))} />
                  <Tee selected={state.tee === '1' ? false : true} value={'tee 10'} onClick={() => setState(prev => ({ ...prev, tee: '10' }))} />
                </>
                : null
        }
      </SwitchContainer>
    );
  }

  const LiveButton = ({ opacity }) => {
    return (
      <LiveContainer opacity={opacity}>
        <Dot />
        <P style={{ fontFamily: 'Source Sans Pro, sans-serif' }} >LIVE</P>
      </LiveContainer>
    );
  }

  const checkLive = () => {
    let tournamentDate = tournament?.startDate
    let tempTournamentDate = tournament?.startDate
    var newDate = moment(tournamentDate).add(24, 'hours')
    return moment().isBetween(tempTournamentDate, moment(newDate))
      ? false
      : true
  }

  return (
    //carousels
    <MainContainer>
      {scoreList?.length > 0
        ? <>
          <div className='flex items-center justify-between w-full p-[20px] px-[2%]'>
            <LiveButton opacity={true} />
            <Switch tee={getTee} />
            <LiveButton opacity={checkLive()} />
          </div>
          <Scoring>
            <div className="flex w-full">
              {scoreList?.map((item, index) => (
                <ScoreStrap item={item} index={index} />
              ))}
            </div>
          </Scoring></>
        : <div className='h-[300px]'>
          <Loader text={'No score found.'} />
        </div>
      }
    </MainContainer>
  );
};

const MainContainer = tw.div`
m-[12px]
rounded-[17px]
flex-col
center
bg-[rgba(34,35,49,0.05)]
`;

const SwitchContainer = tw.div`
flex
justify-center
items-center
md:w-[295px]
w-[242px]
h-[32px]
rounded-full
bg-[rgba(34,35,49,0.07)]
`;

const LiveContainer = tw.div`
${p => (p.opacity ? 'opacity-0' : 'opacity-1')}
px-[8px]
py-[2px]
bg-[rgb(212,49,71)]
rounded-full
flex
items-center
`

const Dot = tw.div`
w-[6px]
h-[6px]
rounded-full
bg-white
`

const P = tw.p`
font-bold
text-[12px]
text-white
ml-[8px]
`

const TeeText = tw.div`
${p => (p.active ? 'border-b-[4px] border-fbButtonColor text-fbButtonColor' : '')}
cursor-pointer
font-medium
text-[15px]
uppercase
h-full
flex
justify-center
items-center
md:w-[128px]
w-[101px]
`

const Container = tw.div`
flex
h-[108px]
w-[212px]
py-[12px]
self-center
items-center
`;

const Scoring = tw.div`
p-3
w-[98%]
h-[500px]
custom-scroll
overflow-auto
`;

const ScoringContainer = tw.div`
flex
flex-col
`;

const ScoreBoxContainer = tw.div`
flex,
flex-col
w-[211px]
h-[84px]
overflow-hidden
bg-white
rounded-[4px]
shadow-3xl
px-[9px]
justify-evenly
`;

const Player = tw.div`
font-medium
text-[15px]
text-gray51
capitalize
`;

const StartSapce = tw.div`
${(p) => (p.first ? 'bg-transparent' : 'bg-genderBorder')}
w-[12px]
h-[1px]
`;

const WinnerContainer = tw.div`
w-[42px]
h-[26px]
center
text-[12px]
font-medium
text-gray51
rounded-[7px]
bg-fbButtonColor/25
uppercase
`;

const Hr = tw.div`
min-h-[1px]
w-full
bg-infoBorder
`;

const HLine = tw.div`
w-[12px]
h-[1px]
bg-genderBorder
`;

const VLine = tw.div`
w-[1px]
bg-genderBorder
`;

const HeadText = tw.div`
text-[12px]
text-lightGray
tracking-[0.14px]
mb-[20px]
text-center
`

export default ScoringWidgets;