import * as types from './types';
import { tournamentApi, matchesApi, scoreApi } from '../../services/api';

export const updateTournament = (id, data) => {
  return {
    type: types.UPDATE_TOURNAMENT,
    payload: tournamentApi.updateTournament(id, data),
  }
}

export const getTournamentList = (item) => {
  return {
    type: types.TOURNAMENTS,
    payload: tournamentApi.getTournamentList(item),
  }
}

export const getDraftTournamentDetails = (data) => {
  return {
    type: types.DRAFT_TOURNAMENT_DETAILS,
    payload: tournamentApi.getDraftTournamentDetails(data),
  }
}

export const getMyDraftTournamentList = (data) => {
  return {
    type: types.MY_DRAFT_TOURNAMENT,
    payload: tournamentApi.getMyDraftTournamentList(data),
  }
}

export const getMyPublishTournamentList = (data) => {
  return {
    type: types.MY_PUBLISH_TOURNAMENT,
    payload: tournamentApi.getMyPublishTournamentList(data),
  }
}

export const getPlayedTournamentList = () => {
  return {
    type: types.PLAYED_TOURNAMENTS,
    payload: tournamentApi.getPlayedTournamentList(),
  }
}

export const getTournamentsPlayer = (data) => {
  return {
    type: types.PLAYER_TOURNAMENT,
    payload: tournamentApi.getTournamentsPlayer(data),
  }
}

export const saveMyTournament = (data) => {
  return {
    type: types.SAVE_MY_TOURNAMENT,
    payload: tournamentApi.saveMyTournament(data),
  }
}

export const allPrizeUpload = (data) => {
  return {
    type: types.ALL_PRIZE_UPLOAD,
    payload: tournamentApi.allPrizeUpload(data),
  }
}

export const updateMyTournament = (id, data) => {
  return {
    type: types.UPDATE_MY_TOURNAMENT,
    payload: tournamentApi.updateMyTournament(id, data),
  }
}

export const uploadTournament = (data) => {
  return {
    type: types.UPLOAD_TOURNAMENTS,
    payload: tournamentApi.uploadTournament(data),
  }
}

export const getPlayedPlayers = (data) => {
  return {
    type: types.PLAYED_PLAYER,
    payload: tournamentApi.getPlayedPlayers(data),
  }
}

export const getTournamentDetails = (data) => {
  return {
    type: types.TOURNAMENT_DETAILS,
    payload: tournamentApi.getTournamentDetails(data),
  }
}

export const validateRegister = (tournamentId) => {
  return {
    type: types.REGISTRATIONS_VALIDATION,
    payload: tournamentApi.validateRegister(tournamentId),
  }
}

export const registrationState = (data) => {
  return {
    type: types.REGISTRATION_STATE,
    payload: tournamentApi.registrationState(data),
  }
}

export const addTournament = (data) => {
  return {
    type: types.TOURNAMENT_DETAILS,
    payload: tournamentApi.addTournament(data),
  }
}

export const getTournamentPlayList = (data) => {
  return {
    type: types.TOURNAMENT_PLAY_LIST,
    payload: tournamentApi.getTournamentPlayList(data),
  }
}

export const getTournamentPrizes = (id) => {
  return {
    type: types.TOURNAMENT_PRIZES,
    payload: tournamentApi.getTournamentPrizes(id),
  }
}

export const blankTournamentDetails = (data) => {
  return {
    type: types.BLANK_TOURNAMENT_DETAILS,
    payload: data,
  }
}

export const getTournamentTypes = () => {
  return {
    type: types.TOURNAMENTS_TYPES,
    payload: tournamentApi.getTournamentTypes(),
  }
}

export const getFacilities = (item) => {
  return {
    type: types.FACILITIES,
    payload: tournamentApi.getFacilities(item),
  }
}

export const getRankings = () => {
  return {
    type: types.LEAGUE_RANKS,
    payload: tournamentApi.getRankings(),
  }
}

export const getClubs = (item) => {
  return {
    type: types.CLUB,
    payload: tournamentApi.getClubs(item),
  }
}

export const getTrack = () => {
  return {
    type: types.TRACK,
    payload: tournamentApi.getTrack(),
  }
}

export const getCourse = () => {
  return {
    type: types.COURSE,
    payload: tournamentApi.getCourse(),
  }
}

export const getRoundsDetailsForUser = (tournamentId) => {
  return {
    type: types.GET_ROUNDS_DETAILS_FOR_USER,
    payload: tournamentApi.getRoundsDetailsForUser(tournamentId),
  }
}

export const cancelParticipation = (tournamentId) => {
  return {
    type: types.CANCEL_PARTICIPATION,
    payload: tournamentApi.cancelParticipation(tournamentId),
  }
}

export const getMatchDetails = (matchId) => {
  return {
    type: types.GET_MATCH_DETAILS_BY_ID,
    payload: matchesApi.getMatchDetails(matchId),
  }
}

export const getMatchScore = (matchId) => {
  return {
    type: types.MATCHESSCORES,
    payload: matchesApi.getMatchScore(matchId),
  }
}

export const getPlayedScore = (matchId) => {
  return {
    type: types.PLAYED_SCORES,
    payload: matchesApi.getPlayedScore(matchId),
  }
}

export const getMatchScoreDetails = (matchId) => {
  return {
    type: types.MATCH_SCORE_DETAILS,
    payload: scoreApi.getMatchScoreDetails(matchId),
  }
}

export const saveScore = (holeScore) => {
  return {
    type: types.SAVE_SCORE,
    payload: scoreApi.saveScore(holeScore)
  }
}

export const finishRound = (matchId) => {
  return {
    type: types.FINISH_MATCH,
    payload: matchesApi.finishRound(matchId)
  }
}

export const finishRoundWithPlayOff = (data) => {
  return {
    type: types.FINISH_MATCH_WITH_PLAYOFF,
    payload: matchesApi.finishRoundWithPlayOff(data)
  }
}

export const updateRounds = () => {
  return {
    type: types.UPDATE_ROUNDS,
    payload: true
  }
}

export const updatePlayData = () => {
  return {
    type: types.UPDATE_PLAY_DATA,
    payload: true
  }
}


//local Data

export const resumeClubs = (data) => {
  return {
    type: types.RESUME_CLUB,
    payload: data,
  }
}
export const resumeTrack = (data) => {
  return {
    type: types.RESUME_TRACK,
    payload: data,
  }
}
export const resumeCourse = (data) => {
  return {
    type: types.RESUME_COURSE,
    payload: data,
  }
}

export const resumeTournamentTypes = (data) => {
  return {
    type: types.RESUME_TOURNAMENT_TYPES,
    payload: data,
  }
}

export const resumeFaciltiy = (data) => {
  return {
    type: types.RESUME_FACILITIES,
    payload: data,
  }
}

export const resumeRanking = (data) => {
  return {
    type: types.RESUME_LEAGUE_RANKS,
    payload: data,
  }
}

export const getLocalTournaments = (data) => {
  return {
    type: types.LOCAL_TOURNAMENTS,
    payload: data,
  }
}

export const deleteTournament = (item) => {
  return {
    type: types.DELETE_TOURNAMENT,
    payload: item,
  }
}

export const addLocalTournament = (data) => {
  return {
    type: types.ADD_LOCAL_TOURNAMENTS,
    payload: data
  }
}

export const addMyTournament = (data) => {
  return {
    type: types.ADD_MY_TOURNAMENTS,
    payload: data
  }
}

export const addInWaiting = (data) => {
  return {
    type: types.WAITING,
    payload: tournamentApi.addInWaiting(data),
  }
}

export const removeMatchDetails = (data) => {
  return {
    type: types.REMOVE_MATCH_DETAILS,
    payload: data
  }
}

export const getUserPrize = (data) => {
  return {
    type: types.USER_PRIZE,
    payload: tournamentApi.getUserPrize(data)
  }
}

export const getUserReward = (data) => {
  return {
    type: types.USER_REWARD,
    payload: tournamentApi.getUserReward(data)
  }
}

export const tournamentSearch = (data) => {
  return {
    type: types.TOURNAMENT_SEARCH,
    payload: matchesApi.tournamentSearch(data)
  }
}
