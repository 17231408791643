import { io as SocketIOClient } from 'socket.io-client';
import Config from '@config';

export const socketFinishRoundKey = 'match';
export const socketScoreKey = 'score';
export const socketPermissionToExit = 'match-permission';
export const common = 'common';

var socket = null;

export const initializeSocket = () => {
    console.log('Config.SOCKET', Config.SOCKET)
    socket = SocketIOClient(Config.SOCKET, { transports: ['websocket'] });
    return socket;
};

export const getSocket = () => {
    return socket;
};

export const registerSocketEvent = (eventName, callback) => {
    socket.on(eventName, callback);
};

export const deregisterSocketEvent = (eventName) => {
    socket.off(eventName);
};

export const sendMessage = (eventName, message, isNet = true) => {
    try {
        isNet &&
            socket.emit(eventName, JSON.stringify(message), (res) => {
                console.log('socket emit', res);
            });
    } catch (error) {
        console.error('sent finish round error:', error);
        // return error
    }
};