import React from 'react';
import tw from 'tailwind-styled-components';
import { useSpring, animated } from '@react-spring/web';

const ProgressBar = ({ intWidth, height, text, barColor, thumbColor }) => {
  const props = useSpring({ from: { width: '0%' }, to: { width: `${intWidth}%` } })
  const value = useSpring({ from: { width: 0 }, to: { width: intWidth } })
  return (
    <ProgressContainer>
      <RegisterTape>
        <div className={RegisterText}>{text || 'Registered'}</div>
        <animated.div className={RegisterText}>{value.width.to(x => `${x.toFixed(0)}%`)}</animated.div>
      </RegisterTape>
      <Bar className='flex items-center' style={{ height: `${height}px`, backgroundColor: `${barColor}` }}>
        <animated.div className={Thumb} style={{ height: '10px', width: props.width, backgroundColor: `${thumbColor}` }} />
      </Bar>
    </ProgressContainer>
  );
};

const ProgressContainer = tw.div`
w-full
`;

const RegisterTape = tw.div`
flex 
justify-between 
w-full 
my-[10px]
`;

const RegisterText = `
font-bold
text-[13px]
tracking-[0.16px]
text-gray51`;

const Bar = tw.div`
h-[12px]
rounded-full
shadow-3xl
bg-white
`;

const Thumb = `
h-full
rounded-full
bg-thumb
`;

export default ProgressBar
