import NextTournament from "@components/nextTournament";
import { connect } from "react-redux";
import MoreGames from '@components/moreGames';
import News from '@components/news';
import { WelcomeHeader, NormalHeader } from '@components';
import Advertisment from '@assets/images/Adword.png';
import './DashboardStyle.css';

const Dashboard = ({ userName, topEight, winnerCount, prizeMoney }) => {

  const data = [
    { title: 'top 8', value: topEight && '' + topEight || '0' },
    { title: 'wins', value: winnerCount && '' + winnerCount || '0' },
    { title: 'prize money', value: prizeMoney && '' + prizeMoney || '0' }
  ]

  const Wrapper = props => {
    return (
      <div className="w-full lg:ml-10">
        {props.children}
      </div>
    )
  }

  const WrapperInner = props => {
    return (
      <div style={props.style} className={`${props.className} lg:block w-full lg:flex-col flex lg:max-h-[680px] custom-scroll p-2 mt-2 justify-center `}>
        {props.children}
      </div>
    );
  }

  return (
    <div>
      <div className="mt-[64px]">
        <WelcomeHeader data={data} userName={userName} />
      </div>
      <div className="mx-auto lg:px-[10%] px-[5%]">
        <div className="lg:flex justify-between">
          <Wrapper>
            <NormalHeader>Next tournament</NormalHeader>
            <WrapperInner style={{ display: 'flex', alignItems: 'center', overflow: 'auto' }}>
              <NextTournament />
              <img src={Advertisment} className={'w-[366px] lg:w-full mx-[24px] lg:mx-auto lg:my-[50px] md:w-[321px] object-fit rounded-[20px]'} />
            </WrapperInner>
          </Wrapper>
          <Wrapper>
            <NormalHeader>more games</NormalHeader>
            <WrapperInner style={{ overflow: 'auto' }}><MoreGames /></WrapperInner>
          </Wrapper>
          <Wrapper>
            <NormalHeader>best golf news</NormalHeader>
            <WrapperInner style={{ overflow: 'auto' }}><News /></WrapperInner>
          </Wrapper>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    userHcp: state.user.hcp,
    userName: state.user.fname,
    topEight: state.user.topEight,
    winnerCount: state.user.winnerCount,
    prizeMoney: state.user.prizeMoney,
  }
}
export default connect(mapStateToProps, null)(Dashboard);