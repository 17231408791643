import tw from 'tailwind-styled-components';
import { Text } from '@components';

const CustomInput = (props) => {
  return (
    <div className={`${props.containerStyle}`}>
      <Text style={{ fontSize: '15px' }}>{props.title}</Text>
      {props.multiline ? <TextArea
        style={{ ...props.inputStyle, marginTop: '1vh' }}
        required={props.required ? true : false}
        {...props}
      />
        :
        <Input
          style={{ ...props.inputStyle, marginTop: '1vh' }}
          required={props.required ? true : false}
          {...props}
        />}
      {props.errorText && <ErrorText>{`${props.errorText}`}</ErrorText>}
    </div>
  );
};

const Input = tw.input`
w-full
p-2
pl-5
focus:outline-none 
focus:ring-1
focus:border-fbButtonColor
border
border-inputBorder
rounded-md`;

const TextArea = tw.textarea`
w-full
p-2
pl-5
focus:outline-none 
focus:ring-1
focus:border-fbButtonColor
border
border-inputBorder
rounded-md`;

const ErrorText = tw.p`
text-sm
text-red-500`

export default CustomInput;
