import React, { useEffect, useState } from 'react';
import tw from 'tailwind-styled-components';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Loader, GamesBox } from '@components/';
import * as actions from '@store/tournament/actions';

const MoreGames = (props) => {

  const [state, setState] = useState({
    clubLoading: false,
    tournamentList: props.tournamentList,
    userCountryId: props.user?.countryId,
  })

  useEffect(() => {
    if (_.isEmpty(props.tournamentList)) {
      setState(prev => ({ ...prev, clubLoading: true }))
      props.getTournamentList(props?.userCountryId).then(res => {
        if (res?.payload?.data && res?.payload?.status === 200) {
          setState(prev => ({ ...prev, clubLoading: false }))
        } else {
          setState(prev => ({ ...prev, clubLoading: false }))
        }
      })
    }
  }, [props.getTournamentList])

  const InnerComponent = () => {
    state.clubLoading && <Loader />

    if (_.isEmpty(props.tournamentList)) {
      return (
        <Loader text={'No tournament found.'} />
      );
    }

    return props.tournamentList.map((item, index) => <GamesBox item={item} index={index} />)
  }

  return (
    <Container>
      <InnerComponent />
    </Container>
  )
}

const Container = tw.div`
flex
lg:flex-col
p-[10px]
min-w-[700px]
lg:min-w-full
`

const mapStateToProps = (state) => {
  return {
    tournamentList: state.tournament.tournamentList,
    userCountryId: state.user.countryId,
    loading: state.tournament.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTournamentList: (data) => dispatch(actions.getTournamentList(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MoreGames)