import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import tw from 'tailwind-styled-components';
import { CustomButton, CustomInput, MainHeaderText, Text } from '@components';
import * as action from '@store/user/actions';
import { isEmail } from '@utils';
import * as actions from '@store/user/actions';

const SignUp = ({ checkEmailAvailability, verifyEmail }) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: '',
    isLoading: false,
    isEmailTaken: false,
    buttonDisable: true,
    errorText: '',
  })

  useEffect(() => {
    if (isEmail(state.email)) {
      setState((prev) => ({
        ...prev,
        buttonDisable: false,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        buttonDisable: true,
      }));
    }
  }, [state.buttonDisable, state.email]);

  let isValidateEmail = state.email === '' ? true : isEmail(state.email)

  const onContinue = () => {
    setState(prev => ({ ...prev, isLoading: true }))
    let email = state.email?.toLocaleLowerCase()
    checkEmailAvailability(email)
      .then((res) => {
        if (res && res.payload && res.payload.data && res.payload.data.count === 1) {
          setState((prev) => ({ ...prev, isLoading: false, isEmailTaken: true }))
        } else if(res && res.payload && res.payload.data && res.payload.data.count === 0) {
            const data = {
              email: state.email.toLowerCase(),
              otp: Math.floor(1000 + Math.random() * 9000),
            };
            verifyEmail(data).then(res => {
              if (res?.payload && res?.payload?.data && res?.payload?.status === 200) {
                let regData = { ...data, from: 'signUp' }
                navigate('/verification', { state: { regData } })
                setState((prev) => ({ ...prev, isLoading: false }))
              } else {
                setState((prev) => ({ ...prev, isLoading: false }))
              }
            })
        } else {
          setState(prev => ({...prev, isLoading: false, errorText: 'Internal server error. Please try again later.'}))
        }
      })
  }


  const Header = () => {
    return (
      <div className={`mb-10 self-start`}>
        <MainHeaderText>
          GET <MainHeaderText className={'text-maroon'}>STARTED</MainHeaderText>
        </MainHeaderText>
        <div className={`my-5`}>
          <Text>Absolutely Free! No credit card needed.</Text>
        </div>
      </div >
    );
  }

  return (
    <LoginPart>
      <div />
      <LoginContent>
        <Header />
        <InputContainer>
          <Input>
            <CustomInput title={'Email address'} placeholder={'Your email'} type={'email'} onChange={(event) => setState(prev => ({ ...prev, email: event.target.value, isEmailTaken: false }))}
              errorText={(isValidateEmail)
                ? state.isEmailTaken
                  ? 'This email is already use by an another user'
                  : state.errorText !== '' ? state.errorText : ''
                : 'Enter a valid email.'
              }
              onKeyUp={e => (e.key === 'Enter') ? onContinue() : null}
            />
          </Input>
          <CustomButton title={'Continue'} onClick={() => onContinue()} className={'mt-[50px] px-4'} disabled={state.buttonDisable} loading={state.isLoading} />
        </InputContainer>
      </LoginContent>
      <div className={'flex justify-start items-center w-[80%]'}>
        <div className={'flex items-center self-start whitespace-nowrap'}>
          <CustomButton title={'Sign In'} onClick={() => navigate('/')} reversStyle />
          <div className={'ml-4 lg:ml-8'}>
            <Text className={'overflow-hidden overflow-ellipsis'}>
              Already have an account?
            </Text>
          </div>
        </div>
      </div>
    </LoginPart>
  );
}

const Input = tw.div`
flex 
flex-col
w-full
py-2`

const LoginPart = tw.div`
flex
py-5
lg:w-full
w-4/5
lg:bg-white
lg:item-start
items-center
flex-col
justify-between`;

const LoginContent = tw.div`
lg:justify-start
lg:items-start
w-[80%]
flex 
flex-col
items-center
justify-center`;

const InputContainer = tw.div`
flex 
flex-col
w-full
lg:w-3/5
lg:justify-start
lg:items-start
justify-center 
items-center`;

const mapDispatchToProps = (dispatch) => {
  return {
    checkEmailAvailability: email => dispatch(action.checkEmailAvailability(email)),
    verifyEmail: data => dispatch(action.verifyEmail(data)),
  }
}
export default connect(null, mapDispatchToProps)(SignUp);