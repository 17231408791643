import * as types from './types';
import { userApi, countryApi } from '@services/api';

export const registerUser = (data) => {
  return {
    type: types.REGISTER_USER,
    payload: userApi.registerUser(data),
  }
}
export const checkEmailAvailability = (email) => {
  return {
    type: types.EMAIL_CHECK,
    payload: userApi.checkEmailAvailability(email),
  }
}
export const login = (data) => {
  return {
    type: types.LOGIN,
    payload: userApi.login(data),
  }
}
export const forgetPass = (data) => {
  return {
    type: types.FORGET_PASS,
    payload: userApi.forgetPass(data),
  }
}
export const verifyEmail = (data) => {
  return {
    type: types.EMAIL_VERIFY,
    payload: userApi.verifyEmail(data),
  }
}
export const logout = ({ data }) => {
  return {
    type: types.LOGOUT,
    payload: data,
  }
}
export const socialLogin = (data) => {
  return {
    type: types.SOCIAL_LOGIN,
    payload: userApi.socialLogin(data),

  }
}
export const updateLocalUser = (data) => {
  return {
    type: types.USER_DETAILS,
    payload: data
  }
}
export const updateUser = (data) => {
  return {
    type: types.UPDATE_USER,
    payload: userApi.updateUser(data)
  }
}
export const updateAppgainUser = (data) => {
  return {
    type: types.APPGAIN_ID,
    payload: userApi.updateAppgainUser(data)
  }
}
export const resetPass = (data) => {
  return {
    type: types.RESET_PASS,
    payload: userApi.resetPass(data)
  }
}
export const getUserData = () => {
  return {
    type: types.GET_USER,
    payload: userApi.getUserData()
  }
}
export const getUserScore = () => {
  return {
    type: types.USER_SCORE,
    payload: userApi.getUserScore()
  }
}
export const resumeUser = (data) => {
  return {
    type: types.GET_USER,
    payload: data
  }
}
export const resumeToken = (data) => {
  return {
    type: types.RESUME_TOKEN,
    payload: data
  }
}
export const uploadImage = (file) => {
  return {
    type: types.UPLOAD_IMAGE,
    payload: userApi.uploadImage(file),
  }
}
export const updateToken = (data) => {
  return {
    type: types.UPDATE_TOKEN,
    payload: data
  }
}
export const getDeepLink = (data) => {
  return {
    type: types.DEEPLINK,
    payload: userApi.getDeepLink(data)
  }
}

export const signInTerms = (type, slug) => {
  return {
    type: types.TERMS,
    payload: userApi.signInTerms(type, slug)
  }
}

export const userTerms = (type, slug) => {
  return {
    type: types.USER_TERMS,
    payload: userApi.userTerms(type, slug)
  }
}

export const allTerms = (slug) => {
  return {
    type: types.ALL_TERMS,
    payload: userApi.allTerms(slug)
  }
}

export const acceptTerms = (data) => {
  return {
    type: types.ACCEPT_TERMS,
    payload: userApi.acceptTerms(data)
  }
}

export const selectLanguage = (data) => {
  return {
    type: types.LANGUAGE_CODE,
    payload: data
  }
}
export const getCountries = () => {
  return {
    type: types.COUNTRIES,
    payload: countryApi.getCountries()
  }
}

export const resumeCountries = (countries) => {
  return {
    type: types.RESUME_COUNTRIES,
    payload: countries
  }
}

export const getFaq = (data) => {
  return {
    type: types.FAQ,
    payload: userApi.getFaq(data)
  }
}

export const faqDetail = (data) => {
  return {
    type: types.FAQ_DETAIL,
    payload: userApi.faqDetail(data)
  }
}

export const changePassword = (data) => {
  return {
    type: types.CHANGE_PASSWORD,
    payload: userApi.changePassword(data)
  }
}